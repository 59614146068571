<template>
    <div class="mid-pla-basic-layout basic-layout3">
        <GlobalHeader />
        <div class="mid-pla-main-content">
            <router-view v-if="isRouterView" />
        </div>
    </div>
</template>

<script>
import GlobalHeader from './components/GlobalHeader'
export default {
    data() {
        return {
            isRouterView: true
        }
    },
    components: {
        GlobalHeader
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    methods: {
        reload() {
            this.isRouterView = false
            this.$nextTick(() => {
                this.isRouterView = true
            })
        }
    }
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>
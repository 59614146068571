<template>
    <div class="md-tabs-wrap" v-if="tabVisible">
        <div class="tabs-inner">
            <div class="tabs-inner-box" ref="globalTabsBox">
                <div class="tabs-item" 
                    :class="{act: tab.active}" 
                    :style="{'padding-right': tab.closeHidden ? '16px' : '30px', width: tabWidth + 'px'}"
                    v-for="tab in tabsShowArray" :key="tab.id"
                    @click="actTabHandle(tab)"
                >
                    <span class="tabs-title inaline" :title="tab.title">{{tab.title}}</span>
                    <i class="icon-close" v-if="!tab.closeHidden" title="关闭"
                        @click.prevent.stop="closeTabPage(tab)"
                    >&times;</i>
                </div>
            </div>
            <div class="dropdown-wrap"  v-if="tabsHideArray.length > 0"
                @mousemove="dropdownShow = true"
                @mouseleave="dropdownShow = false"
            >
                <div class="ic-more-box" :class="{active: dropdownShow}">
                    <a-icon class="ic-more" type="double-right" />
                </div>
                <div class="dropdown" v-show="dropdownShow">
                    <div class="dropdown-inner">
                        <div class="dropdown-tabs-item" :class="{act: tab.active}" v-for="(tab) in tabsHideArray" :key="tab.id"
                            @click="actTabHandle(tab)" :title="tab.title"
                        >
                            <span class="dropdown-tabs-title inaline">{{tab.title}}</span>
                            <i class="dropdown-tabs-icon-close" v-if="!tab.closeHidden" title="关闭"
                                @click.prevent.stop="closeTabPage(tab)"
                            >&times;</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import { nanoid } from 'nanoid'
export default {
    name: 'TabsNav',
    data() {
        return {
            tabVisible: false,

            tabsShowArray: [],
            tabsHideArray: [],
            tabWidth: 120,
            dropdownShow: false,

            // first-render
			isFirstRender: true,

            timer: null
        }
    },
    computed: {
        ...mapState({
            tabs: state => state.app.tabs,
            tabsParentRoute: state => state.app.tabsParentRoute,
        })
    },
    watch: {
        $route: {
            handler(route) {
                this.findDefaultTabs(route)
            },
            immediate: true
        },
        '$store.state.app.tabChangeFlag': {
            handler(value) {
                console.log('$store.state.app.tabChangeFlag')
                if (value) {
                    this.transferTabs()
                }
            },
            immediate: true
        }
    },
    created() {
        this.onResize()
    },
    activated() {
        console.log('tabsNav:activated')
    },
    deactivated() {
        console.log('tabsNav:deactivated')
    },
    methods: {
        findDefaultTabs(route) {
            console.log('findDefaultTabs: this.$router.options.routes', this.$router.options.routes)
            console.log('findDefaultTabs: this.$route', this.$route)
            let routeLevel = 0
            let tabNavs = route.matched.filter((r, i) => {
                if (r.meta && r.meta.subType) {
                    routeLevel = i
                    return true
                } else {
                    return false
                }
            })
            console.log('tabNavs', tabNavs)
            if (tabNavs.length > 0) {
                this.tabVisible = true
                let tabsParentRoute = '' // tab标签的父级
                let menuTabs = []         // 匹配到的tab标签
                let result = []          // tab标签对应的缓存数组

                tabsParentRoute = route.matched[routeLevel].path

                let menu =  this.$router.options.routes.filter(item => item.name === 'BasicLayout')[0].children
                for (let i = 0; i < routeLevel; i ++) {
                    menu = menu.filter(item => this.$route.matched[i+1].path === item.path)[0].children
                }
                menuTabs = menu.filter(item => item.meta.defaultTab)

                result =  menuTabs.map((item) => {
                    return {
                        path: item.path,        // 页面路径
                        active: item.path === this.$route.path,   // 当前tab页是否展示
                        title: item.meta.title,       // 页面标题
                        id: nanoid(),          // 页面id
                        closeHidden: item.meta.closeHidden || false  // 是否可关闭
                    }
                })
                if ((this.tabs.length == 0 || this.tabsParentRoute != tabsParentRoute) && result.length > 0) { 
                    
                    this.$store.commit('setTabsParentRoute', tabsParentRoute)
                    if (result.every(item => item.path != this.$route.fullPath)) {
                        result.forEach(r => {
                            r.active = false
                        })
                        let curTab = {
                            path: this.$route.fullPath,        // 页面路径
                            active: true,   // 当前tab页是否展示
                            title: this.$route.meta.title,       // 页面标题
                            id: nanoid(),          // 页面id
                            closeHidden: this.$route.meta.closeHidden || false  // 是否可关闭
                        }
                        result = result.concat(curTab)
                        console.log('::::result:::', result)
                    }
                    // console.log('tabsNav:clearAllTabCompInstance111')
                    this.$store.dispatch('clearAllTabCompInstance') // 销毁前面的标签缓存
                    this.$store.commit('setTabs', result)
                    console.log(result)
                    this.$store.commit('setTabChangeFlag')
                } else {
                    console.log(this.$route)
                    if (this.$route.meta.title) {
                        this.$store.dispatch('updateTabTitle', {
                            path: this.$route.fullPath,
                            title: this.$route.meta.title
                        })
                    }
                }
            } else {
                this.tabVisible = false
                // console.log('tabsNav:clearAllTabCompInstance222')
                this.$store.dispatch('clearAllTabCompInstance') // 销毁前面的标签缓存
                this.$store.commit('setTabs', [])
                this.$store.commit('setTabChangeFlag')
            }
        },
        actTabHandle(tab) {
            this.actTabPage(tab)
        },
        onResize() {
			let _this = this
			this.$nextTick(() => {
				_this.transferTabs()
				if (!this.isFirstRender) return
				this.isFirstRender = false
				window.addEventListener("resize", function() {
					// console.log('resize')
                    clearTimeout(_this.timer)
                    _this.timer = setTimeout(() => {
                        _this.transferTabs()
                    }, 200)
					
				})
			})
		},
		transferTabs() {
			console.dir("this.$refs['globalTabsBox']", this.$refs['globalTabsBox'])
			let pNode = this.$refs['globalTabsBox']
			if (pNode) {
				let sumW = pNode.clientWidth
				let itemW = 126  // 100 + 6  120为单个宽度  6为外边距
				// console.log(sumW, itemW)
				let showNum = Math.floor(sumW / itemW)
				this.tabWidth = Math.floor(sumW / showNum - 6)
				let beforeArray = this.tabs.slice(0,showNum)
				let afterArray = this.tabs.slice(showNum)
				let activeIndex = afterArray.findIndex(item => item.active)
				if (activeIndex>=0) {
					let actItem = afterArray[activeIndex]
					let popItem = beforeArray.pop()
					afterArray.splice(activeIndex, 1)
					beforeArray.push(actItem)
					afterArray.unshift(popItem)
				}
				this.tabsShowArray = [...beforeArray]
				this.tabsHideArray = [...afterArray]

                console.log('this.tabsShowArray', this.tabsShowArray, this.tabsHideArray)
			}
		},
    }
}
</script>

<style scoped>

</style>
<template>
    <keep-alive v-if="isTabContent">
        <router-view :key="key"></router-view>
    </keep-alive>
    <!-- <router-view :key="key"></router-view> -->
    <!-- <router-view :key="$route.fullPath"></router-view> -->
</template>

<script>
export default {
    name: 'KeepAliveRouteView',
    data() {
        return {
           isTabContent: true,
           key: ''
        }
    },
    provide() {
        return {
            tabReload: this.tabReload
        }
    },
    watch: {
        '$route': {
            handler() {
                let endStr = this.$store.getters.activeTab && this.$store.getters.activeTab.id ?
                '#' + this.$store.getters.activeTab.id : ''
                this.key = this.$route.fullPath + endStr
                console.log(this.key)
            },
            immediate: true
        }
    },
    methods: {
        tabReload() {
            this.isTabContent = false
            this.$nextTick(() => {
                this.isTabContent = true
            })
        }
    }
}
</script>
<template>
    <div class="mid-pla-basic-layout" :class="{'has-horizontal-nav': horizontalNavShow}">
        <GlobalHeader />
        <SiderMenu />
        <HorizontalNav @horizontal-nav-emit="horizontalNavEmit" />
        <div class="mid-pla-main-content">
            <TabsNav />
            <router-view v-if="isRouterView" />
        </div>
    </div>
</template>

<script>
import SiderMenu from './components/SiderMenu'
import GlobalHeader from './components/GlobalHeader'
import HorizontalNav from './components/HorizontalNav'
import TabsNav from './components/TabsNav'
// import GlobalSearch from './components/GlobalSearch'
export default {
    data() {
        return {
            isRouterView: true,
            horizontalNavShow: true
        }
    },
    components: {
        SiderMenu,
        GlobalHeader,
        HorizontalNav,
        TabsNav,
        // GlobalSearch
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    methods: {
        reload() {
            this.isRouterView = false
            this.$nextTick(() => {
                this.isRouterView = true
            })
        },
        horizontalNavEmit(data) {
            console.log('horizontalNavEmit', data)
            this.horizontalNavShow = data
        }
    }
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>
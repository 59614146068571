import axios from 'axios'
import API_PREFIX from '../config/api.prefix'
import {message} from 'ant-design-vue'
import {getSign, createNonce} from '../utils/tools'
import qs from 'qs'
import apiKeys from '@/config/api.key'

function createRequest(baseURL, accessKey = apiKeys.accessKey, secretKey = apiKeys.secretKey) {
    // 创建axios实例
    const request = axios.create({
        // API 请求的默认前缀
        baseURL: baseURL || API_PREFIX.base_url,
        // 请求超时时间
        timeout: 10000,

        headers: {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        }
    })

    request.interceptors.request.use(config => {
        // 在发送请求之前做些什么
        // console.log(config)
        // console.log('config......')
        let timestamp = Date.now()
        let nonce = createNonce()

        let signObj
        if (config.method === 'get') {
            let params = switchParams(config.params)
            config.params = {
                ...params
            }
            signObj = {
                ...config.params,
                'X-validate-accessKey': accessKey,
                'X-validate-timestamp': timestamp,
                'X-validate-nonceStr': nonce
            }
        } else {
            let data = switchParams(config.data)
            config.data = {
                ...data
            }
            signObj = {
                ...config.data,
                'X-validate-accessKey': accessKey,
                'X-validate-timestamp': timestamp,
                'X-validate-nonceStr': nonce
            }
        }

        let sign = getSign(signObj, secretKey)
        // config.headers['Accept'] = '*/*'
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        config.headers['X-validate-accessKey'] = accessKey
        config.headers['X-validate-timestamp'] = timestamp
        config.headers['X-validate-nonceStr'] = nonce
        config.headers['X-validate-sign'] = sign

        if (config.headers['Content-Type'].indexOf('application/x-www-form-urlencoded') >= 0 && config.data) {
            config.data = qs.stringify(config.data)
        }
        // console.log(config)
        return config
    }, error => {
        // 对请求错误做些什么
        return Promise.reject(error)
    });

    request.interceptors.response.use(response => {
        // 对响应数据做点什么
        if (!response.data.code) {
            message.error('网络异常，请稍后重试')
        }
        return response.data
    }, error => {
        // 对响应错误做点什么
        message.error('网络异常，请稍后重试')
        return Promise.reject(error)
    })

    return request
}

function switchParams(params) {
    // console.log('switchParams', params)
    let paramsObj = {}
    for (let key in params) {
        let value = params[key]
        if (value !== '' && value !== null && value !== undefined) {
            paramsObj[key] = value
        }
    }
    return paramsObj
}


export default createRequest


<template>
    <div class="mid-pla-sider-menu">
        <div class="level-1"  v-if="false">
            <div class="mp-avatar">
                <div class="mp-avatar-box" style="border-color: #444" v-if="adminAvatar" @click="toUserProfile">
                    <img class="img" :src="adminAvatar">
                </div>
                <div class="mp-default-avatar" @click="toUserProfile" v-else>
                    {{adminRealnameShow}}
                </div>
                <span class="hover-tip">个人中心</span>
            </div>
            <ul class="menu-1-list">
                <li v-for="(item) in menuFirstRoutes" :key="item.name"
                    class="item"
                    :class="{active: curFirstPath === item.path || item.hover}" 
                    @mouseenter="item.hover = true"
                    @mouseleave="item.hover = false"
                    @click="toPath(item.path)"
                >
                    <mp-icon :icon="'ic-' + item.meta.icon" :act="curFirstPath === item.path || item.hover" class="item-ic"/>
                    <span class="hover-tip">{{item.meta.title}}</span>
                </li>
            </ul>

            <div class="bottom-icons">
                <div v-for="(item) in bottomIconList" :key="item.id"
                    class="item"
                    :class="{active: curFirstPath === item.path || item.hover}" 
                    @mouseenter="item.hover = true"
                    @mouseleave="item.hover = false"
                    @click="clickIconHandle(item)"
                >
                    <mp-icon :icon="'ic-' + item.icon" :act="curFirstPath === item.path || item.hover" class="item-ic"/>
                    <span class="hover-tip">{{item.title}}</span>
                </div>
            </div>
        </div>
        <div class="level-2" v-if="menuSecondRoutes.length > 0">
            <ul class="menu-2-list">
                <li v-for="item in menuSecondRoutes" :key="item.path"
                    class="item" :class="{active: curSecondPath === item.path}" 
                    @click="toPath(item.path)" 
                >
                    <i class="siderbar-icon" :class="item.meta.icon"></i>
                    <span class="title">{{item.meta.title}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// import icAvatar from '../../../assets/icons/ic_avatar.svg'
import MpIcon from '@/components/MpIcon'
import {mapGetters, mapState} from 'vuex'
export default {
    components: {
        // icAvatar,
        MpIcon
    },
    data() {
        return {
            menuFirstRoutes: [],
            menuSecondRoutes: [],
            bottomIconList: [
                {   
                    id: 1,
                    icon: 'system-config',
                    title: '系统管理',
                    path: '',
                    hover: false
                },
                {
                    id: 2,
                    icon: 'quit',
                    title: '退出登录',
                    path: '',
                    hover: false
                }
            ]
        }
    },
    inject: ["reload"],
    watch: {
        $route: {
            handler(route) {
                this.menuFirstRoutes = this.$router.options.routes
                    .filter(item => item.name === 'BasicLayout')[0].children
                    .map(item => ({
                        ...item,
                        hover: false
                    }))
                
                let menuSecondRoute = this.menuFirstRoutes
                    .filter(item => this.$route.matched[1].path === item.path)[0]
                if (menuSecondRoute) {
                    this.menuSecondRoutes = menuSecondRoute.children || []
                } else {
                    this.menuSecondRoutes = []
                }
            },
            immediate: true
        }
    },
    methods: {
        toPath(path) {
            this.$router.push(path).catch(() => {
                console.log('this.reload()', this.reload)
                this.reload()
            })
        },
        toUserProfile() {
            this.$router.push('/user/profile')
        },
        clickIconHandle(item) {
            if (item.icon === 'quit') {
                // 退出登录
                this.quitHandle()
            }
        },
        // 退出登录
        quitHandle() {
            this.$confirm({
                title: '确定退出登录吗?',
                centered: true,
                onOk: () => {
                    console.log('OK');
                    this.$store.dispatch('logOut')
                },
                onCancel: () =>{
                    console.log('Cancel');
                },
            })
        }
    },
    computed: {
        ...mapGetters(['adminRealname']),
        ...mapState({
            adminAvatar: state => state.user.adminAvatar
        }),
        curFirstPath() {
            return this.$route.matched[1].path
        },
        curSecondPath() {
            return this.$route.matched[2].path
        },
        adminRealnameShow: function() {
            return this.adminRealname.slice(this.adminRealname.length - 2)
        }
    }
}
</script>
import API_ENV from "./api.env"

let apiKeys = {}

if (API_ENV === 'local') {
  apiKeys = {
    accessKey: '0000000',
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',
    ipKey: 'MozEh4JQbCy06IYh7lQs5Og4Q6mVnfRC',
    imageSecurityKey: 'vLZo4cqxmhA5klyWyXW5',
  }
}

if (API_ENV === 'dev') {
  apiKeys = {
    accessKey: '0000000',
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',
    ipKey: 'MozEh4JQbCy06IYh7lQs5Og4Q6mVnfRC',
    imageSecurityKey: 'vLZo4cqxmhA5klyWyXW5',
  }
}

if (API_ENV === 'test') {
  apiKeys = {
    accessKey: '0000000',
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',
    ipKey: 'MozEh4JQbCy06IYh7lQs5Og4Q6mVnfRC',
    imageSecurityKey: 'vLZo4cqxmhA5klyWyXW5',
  }
}

if (API_ENV === 'prod') {
  apiKeys = {
    accessKey: '0000000',
    secretKey: 'az5CAEUb0qsEAZRThvmp8FD8tawR8jrx',
    ipKey: 'MozEh4JQbCy06IYh7lQs5Og4Q6mVnfRC',
    imageSecurityKey: 'vLZo4cqxmhA5klyWyXW5',
  }
}

export default apiKeys

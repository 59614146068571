import { setTimeout } from "core-js"

export default {
    created() {
        if (this.$options.keep_alive_tab) {
            setTimeout(() => { // 延时执行
                console.log('mixin:created....')
                this.$store.commit('updateTabCompInstance', this)
            }, 0)
        }
    },
    methods: {
        pushTabPage(item) {
            console.log('============pushTabPage============')
            this.$store.dispatch('addTabs', {
                active: true,
                path: item.path,
                componentInstance: null,
                startIndex: item.startIndex || 0
            })
            this.$store.commit('setTabChangeFlag')
            this.$router.replace(item.path).catch(err => console.log(err))
        },
        actTabPage(item) {
            console.log('============actTabPage============')
            this.$store.commit('actTabItem', item)
            this.$store.commit('setTabChangeFlag')
            this.$router.replace({path: item.path}).catch(err => err)
        },
        closeTabPage(item) {
            console.log('============closeTabPage=================')
            console.log(this.$parent)
            console.log(this.$store)
            console.log('this.$store.getters.activeTabIndex', this.$store.getters.activeTabIndex)
            let index
            let closedTab
            let oldActiveTabIndex = this.$store.getters.activeTabIndex
            if (typeof item === 'undefined') {
                index = oldActiveTabIndex
                closedTab = this.$store.getters.activeTab
            } else {
                index = this.$store.state.app.tabs.findIndex(t => t.id === item.id)
                closedTab = item 
            }
            this.$store.dispatch('closeTab', closedTab)
            this.$store.commit('setTabChangeFlag')

            if (index === oldActiveTabIndex) {
                let tabs = this.$store.state.app.tabs
                let newActiveTabIndex = this.$store.getters.activeTabIndex
                console.log('newActiveTabIndex', newActiveTabIndex)
                this.$router.replace({path: tabs[newActiveTabIndex].path}).catch(err => console.log(err))
            }
        }
    }
}
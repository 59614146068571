import {nanoid} from 'nanoid'
const app = {
    state: {
        spinning: {
            loading: false,
            tip: ''
        },
        tabsParentRoute: '',
        tabs: [         // 标签页列表
            // {
            //     path: '',        // 页面路径
            //     active: false,   // 当前tab页是否展示
            //     title: '',       // 页面标题
            //     id: '',          // 页面id
            //     closeHidden: false  // 是否可关闭
            // }
        ],
        keepAlivePages: [],    // 存储的page组件的name
        tabChangeFlag: ''      // 更新tab的标记
    },
    getters: {
        activeTab: state => {
            let activeTabs
            if (state.tabs && Array.isArray(state.tabs)) {
                activeTabs = state.tabs.filter(tab => tab.active) 
            }
            return activeTabs && activeTabs[0]
        },
        activeTabIndex: state => {
            if (state.tabs && Array.isArray(state.tabs)) {
                return state.tabs.findIndex(tab => tab.active) 
            }
            return -1
        }
    },
    mutations: {
        setSpinning(state, data) {
            state.spinning = data
        },
        setTabsParentRoute(state, data) {
            state.tabsParentRoute = data
        },
        updateTabCompInstance(state, data) {
            let updateTabIndex = -1
            if (state.tabs && Array.isArray(state.tabs)) {
                updateTabIndex = state.tabs.findIndex(tab => tab.active) 
            }
            console.log('updateTabCompInstance', 'state.updateTabIndex', state.tabs[updateTabIndex], updateTabIndex)
            if (state.tabs[updateTabIndex] && !(state.tabs[updateTabIndex].componentInstance)) {
                state.tabs[updateTabIndex].componentInstance = data
            }
            console.log(state.tabs)
        },
        actTabItem(state, data) {
            let newTabs = state.tabs.map(tab => {
                if (data.id == tab.id || data.path == tab.path) {
                    return {
                        ...tab,
                        active: true
                    }
                }
                return {
                    ...tab,
                    active: false
                }
            })
            state.tabs = [...newTabs]
        },
        updateTabTitle(state, data) {
            let newTabs = state.tabs.map(tab => {
                if (data.path == tab.path) {
                    return {
                        ...tab,
                        title: data.title
                    }
                }
                return {
                    ...tab,
                }
            })
            state.tabs = [...newTabs]
        },
        setTabs(state, data) {
            state.tabs = data
        },
        setTabChangeFlag(state) {
            state.tabChangeFlag = '' + Date.now() + Math.random()
        }
    },
    actions: {
        addTabs({state, commit, getters}, data) {
            if (state.tabs.some(item => item.path == data.path)) {
                commit('actTabItem', data)
                return
            }
            let tab = {
                componentInstance: data.componentInstance,
                path: data.path,        
                active: data.active || false,
                title: '',       // 页面标题
                id: nanoid(),          // 页面id
                closeHidden: false  // 是否可关闭
            }

            let tabs = [...state.tabs]
            let oldActiveTabIndex = getters.activeTabIndex
            if (data.active) {
                tabs[getters.activeTabIndex].active = false
            }

            let insertIndex = oldActiveTabIndex + 1
            if (data.startIndex && oldActiveTabIndex + 1 < data.startIndex) {
                insertIndex = data.startIndex
            }
            tabs.splice(insertIndex, 0, tab)
            commit('setTabs', [...tabs])
        },
        closeTab({state, commit}, tab) {
            console.log('tab.componentInstance', tab)
            if (tab.componentInstance) tab.componentInstance.$destroy()
            let index = -1
            let newTabs = state.tabs.filter((t, i) => {
                if (t.active) index = i
                return t.id !== tab.id
            })
            if (tab.active) {
                index <= newTabs.length - 1 ?
                newTabs[index].active = true :
                newTabs[newTabs.length - 1].active = true
            }
            commit('setTabs', newTabs)
        },
        clearAllTabCompInstance({state}) {
            state.tabs.forEach(tab => {
                console.log('clearAllTabCompInstance:tab', tab)
                if (tab.componentInstance) tab.componentInstance.$destroy()
            })
        },
        updateTabTitle({commit, getters}, data) {
            if (!getters.activeTab.title || data.force) {
                commit('updateTabTitle', data)
                commit('setTabChangeFlag')
            }
        }
    }
}

export default app
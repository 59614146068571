<template>
    <div class="mid-pla-global-header">
        <div class="system-title">
            <img class="logo" src="../../../assets/imgs/logo.png" title="产业数据中台" alt="产业数据中台">
            <span class="text">产业数据中台</span>
        </div>
        <ul class="menu-list">
            <li v-for="(item) in menuFirstRoutes" :key="item.name"
                class="item"
                :class="{active: curFirstPath === item.path}" 
                @click="toPath(item.path)"
            >
                {{item.meta.title}}
            </li>
        </ul>
        <div class="header-right">
            <div class="right-icons">
                <div class="item">
                    <i class="item-ic system"></i>
                </div>
                <div class="item" @click="quitHandle">
                    <i class="item-ic quit"></i>
                </div>
            </div>
            <div class="mp-avatar">
                <div class="mp-avatar-box" style="border-color: #444" v-if="adminAvatar" @click="toUserProfile">
                    <img class="img" :src="adminAvatar">
                </div>
                <div class="mp-default-avatar" @click="toUserProfile" v-else>
                   管理员
                </div>
                <!--  {{adminRealnameShow}} -->
            </div>
        </div>
    </div>
</template>

<script>
// import MpIcon from '@/components/MpIcon'
import {mapGetters, mapState} from 'vuex'
export default {
    components: {
        // MpIcon
    },
    data() {
        return {
            menuFirstRoutes: [],
        }
    },
    computed: {
        ...mapGetters(['adminRealname']),
        ...mapState({
            adminAvatar: state => state.user.adminAvatar
        }),
        curFirstPath() {
            return this.$route.matched[1].path
        },
        adminRealnameShow: function() {
            return this.adminRealname.slice(this.adminRealname.length - 2)
        }
    },
    watch: {
        $route: {
            handler(route) {
                this.menuFirstRoutes = this.$router.options.routes
                    .filter(item => item.name === 'BasicLayout')[0].children
                    .map(item => ({
                        ...item,
                        hover: false
                    }))
            },
            immediate: true
        }
    },
    inject: ["reload"],
    methods: {
        toPath(path) {
            this.$router.push(path).catch(() => {
                this.reload()
            })
        },
        toUserProfile() {
            this.$router.push('/user/profile')
        },
        // 退出登录
        quitHandle() {
            this.$confirm({
                title: '确定退出登录吗?',
                centered: true,
                onOk: () => {
                    console.log('OK');
                    this.$store.dispatch('logOut')
                },
                onCancel: () =>{
                    console.log('Cancel');
                },
            })
        }
    }
}
</script>
/*
* 按需加载 Icons
*
* 请注意，如果出现有组件图标不显示，
* 请将该组件使用到的图标在这里引入
**/

/* Layout begin */
export {
    default as Left,
} from '@ant-design/icons/lib/outline/LeftOutline'
export {
    default as Right,
} from '@ant-design/icons/lib/outline/RightOutline'
export {
    default as Down,
} from '@ant-design/icons/lib/outline/DownOutline'
export {
    default as Up,
} from '@ant-design/icons/lib/outline/UpOutline'
export {
    default as Close,
} from '@ant-design/icons/lib/outline/CloseOutline'
export {
    default as Loading,
} from '@ant-design/icons/lib/outline/LoadingOutline'

export {
    default as Calendar
} from '@ant-design/icons/lib/outline/CalendarOutline'
export {
    default as Check
} from '@ant-design/icons/lib/outline/CheckOutline'
export {
    default as Plus
} from '@ant-design/icons/lib/outline/PlusOutline'
export {
    default as Sync
} from '@ant-design/icons/lib/outline/SyncOutline'
export {
    default as DoubleRight
} from '@ant-design/icons/lib/outline/DoubleRightOutline'

export {
    default as VerticalAlignTop
} from '@ant-design/icons/lib/outline/VerticalAlignTopOutline'

export {
    default as VerticalAlignBottom
} from '@ant-design/icons/lib/outline/VerticalAlignBottomOutline'

export {
    default as Swap
} from '@ant-design/icons/lib/outline/SwapOutline'

export {
    default as Download
} from '@ant-design/icons/lib/outline/DownloadOutline'

export {
    default as Upload
} from '@ant-design/icons/lib/outline/UploadOutline'

/* Feedback begin */
export {
    default as QuestionCircleOutline,
} from '@ant-design/icons/lib/outline/QuestionCircleOutline'

export {
    default as CheckCircleOutline,
} from '@ant-design/icons/lib/outline/CheckCircleOutline'

export {
    default as ExclamationCircleOutline,
} from '@ant-design/icons/lib/outline/ExclamationCircleOutline'

export {
    default as InfoCircleOutline,
} from '@ant-design/icons/lib/outline/InfoCircleOutline'

export {
    default as CloseCircleOutline,
} from '@ant-design/icons/lib/outline/CloseCircleOutline'

export {
    default as DeleteOutline,
} from '@ant-design/icons/lib/outline/DeleteOutline'

export {
    default as EditOutline,
} from '@ant-design/icons/lib/outline/EditOutline'

export {
    default as KeyOutline,
} from '@ant-design/icons/lib/outline/KeyOutline'

export {
    default as SearchOutline,
} from '@ant-design/icons/lib/outline/SearchOutline'

export {
    default as LeftCircleOutline,
} from '@ant-design/icons/lib/outline/LeftCircleOutline'

export {
    default as RightCircleOutline,
} from '@ant-design/icons/lib/outline/RightCircleOutline'


/* Feedback end */

export {
    default as CloseCircle,
} from '@ant-design/icons/lib/fill/CloseCircleFill'
export {
    default as CheckCircle,
} from '@ant-design/icons/lib/fill/CheckCircleFill'
export {
    default as ExclamationCircle,
} from '@ant-design/icons/lib/fill/ExclamationCircleFill'
export {
    default as InfoCircle,
} from '@ant-design/icons/lib/fill/InfoCircleFill'

import Vue from 'vue'

// 按需引入组件
import { 
    ConfigProvider,
    Button,
    Icon,
    Avatar,
    Table,
    Badge,
    Tag,
    Form,
    FormModel,
    Input,
    Select,
    Radio,
    Checkbox,
    Upload,
    Drawer,
    Row,
    Col,
    Spin,
    Modal,
    message,
    notification,
    DatePicker,
    Tooltip,
    List,
    Tree,
    Cascader,
    Empty,
    InputNumber,
    Popover,
    Tabs,
    Carousel,
    Pagination
} from 'ant-design-vue'

Vue.use(ConfigProvider)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Avatar)
Vue.use(Table)
Vue.use(Badge)
Vue.use(Tag)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Select)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Upload)
Vue.use(Drawer)
Vue.use(Row)
Vue.use(Col)
Vue.use(Spin)
Vue.use(Modal)
Vue.use(DatePicker)
Vue.use(Tooltip)
Vue.use(List)
Vue.use(Tree)
Vue.use(Cascader)
Vue.use(Empty)
Vue.use(InputNumber)
Vue.use(Popover)
Vue.use(Tabs)
Vue.use(Carousel)
Vue.use(Pagination)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

process.env.NODE_ENV !== 'production' && console.warn('[antd-pro] NOTICE: Antd use lazy-load.')
import API_ENV_DEVELOPMENT from './api.env.development'

let API_ENV

// 本地开发环境， API_ENV的值取决于API_ENV_DEVELOPMENT的值
if (process.env.NODE_ENV === 'development') {
    API_ENV = API_ENV_DEVELOPMENT
} 

// 打包时， API_ENV的值取决于process.env.VUE_APP_API_ENV的值，分别为 dev test prod
else if (process.env.NODE_ENV === 'production') {
    API_ENV = process.env.VUE_APP_API_ENV
}

else {
    console.warn('Unexpected environment');
}

export default API_ENV
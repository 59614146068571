<script>
import IcAppPermissions from '@/assets/icons/ic_app_permissions.svg'
import IcAppPermissionsAct from '@/assets/icons/ic_app_permissions_act.svg'
import IcBasicData from '@/assets/icons/ic_basic_data.svg'
import IcBasicDataAct from '@/assets/icons/ic_basic_data_act.svg'
import IcDataAnalysis from '@/assets/icons/ic_data_analysis.svg'
import IcDataAnalysisAct from '@/assets/icons/ic_data_analysis_act.svg'
import IcDataModel from '@/assets/icons/ic_data_model.svg'
import IcDataModelAct from '@/assets/icons/ic_data_model_act.svg'

import IcSystemConfig from '@/assets/icons/ic_system_config.svg'
import IcSystemConfigAct from '@/assets/icons/ic_system_config_act.svg'
import IcQuit from '@/assets/icons/ic_quit.svg'
import IcQuitAct from '@/assets/icons/ic_quit_act.svg'
export default {
    props: ['icon', 'act'],
    components: {
        IcAppPermissions,
        IcAppPermissionsAct,
        IcBasicData,
        IcBasicDataAct,
        IcDataAnalysis,
        IcDataAnalysisAct,
        IcDataModel,
        IcDataModelAct,
        IcSystemConfig,
        IcSystemConfigAct,
        IcQuit,
        IcQuitAct
    },
    render () {
        let {icon, act} = this.$props
        if (icon === 'ic-app-permissions') {
            return act ? <ic-app-permissions-act /> : <ic-app-permissions />
        }
        if (icon === 'ic-basic-data') {
            return act ? <ic-basic-data-act /> : <ic-basic-data />
        }
        if (icon === 'ic-data-analysis') {
            return act ? <ic-data-analysis-act /> : <ic-data-analysis />
        }
        if (icon === 'ic-data-model') {
            return act ? <ic-data-model-act /> : <ic-data-model />
        }
        if (icon === 'ic-system-config') {
            return act ? <ic-system-config-act /> : <ic-system-config />
        }
        if (icon === 'ic-quit') {
            return act ? <ic-quit-act /> : <ic-quit />
        }
        return null
    }
}
</script>

<style>

</style>
import Cookies from 'js-cookie'


export const cookiesList = {
    token: 'token'
}

export function setCookie(key, value, expires) {
    // expires: 过期时间
    return Cookies.set(key, value, { expires: expires || '' })
}

export function getCookie(key) {
    return Cookies.get(key)
}

export function removeCookie (key) {
    return Cookies.remove(key)
}

// 删除所有 cookie
function deleteCookies() {
    let expires = new Date(0).toUTCString();
    let cookieStr = document.cookie;
    let cookieList = cookieStr.split('; ');
    for (let i = 0; i < cookieList.length; i ++) {
        let key = cookieList[i].split('=')[0]
        document.cookie = key + '=; expires=' + expires
    }
}

export function clearLoginData () {
    // 删除所有cookie
    deleteCookies()

    // 删除localStorage
    localStorage.clear()
}
  


// 测试
// setCookie('test111', 'test1111')
// removeCookie('test111')
// setCookie('test111', 'test', 1)
// 用户数据
export const userListData = {
  "code": 200,
  "success": true,
  "data": {
    "current": 1,
    "size": 10,
    "total": 3097,
    "pages": 310,
    "records": [
      {
        "userId": 5501,
        "userCellphone": "15068885130",
        "userNickname": null,
        "companyNames": '利辰服饰',
        "relatedApplications": "1",
        "userSource": 1,
        "userRegisterTime": "2019-05-16 16:20:28",
        "userLastLoginTime": "2021-08-27 15:42:28",
        "userStatus": "y"
      },
      {
        "userId": 5500,
        "userCellphone": "15888286818",
        "userNickname": null,
        "companyNames": '杭州绅锐服饰有限公司',
        "relatedApplications": "1",
        "userSource": 1,
        "userRegisterTime": "2020-12-26 15:01:58",
        "userLastLoginTime": "2021-08-24 17:48:58",
        "userStatus": "y"
      },
      {
        "userId": 5499,
        "userCellphone": "13958770977",
        "userNickname": null,
        "companyNames": '杭州高珂服饰有限公司',
        "relatedApplications": "1",
        "userSource": 1,
        "userRegisterTime": "2019-08-25 16:01:38",
        "userLastLoginTime": "2021-08-24 17:18:38",
        "userStatus": "y"
      },
      {
        "userId": 5498,
        "userCellphone": "13750849921",
        "userNickname": null,
        "companyNames": '杭州沐睿服饰有限公司',
        "relatedApplications": "1",
        "userSource": 1,
        "userRegisterTime": "2018-12-23 16:48:51",
        "userLastLoginTime": "2021-08-18 09:54:51",
        "userStatus": "y"
      },
      {
        "userId": 5497,
        "userCellphone": "13588439993",
        "userNickname": null,
        "companyNames": '林仙景服装设计工作室',
        "relatedApplications": "1,2",
        "userSource": 1,
        "userRegisterTime": "2018-12-25 16:32:34",
        "userLastLoginTime": "2021-08-12 10:06:34",
        "userStatus": "y"
      },
      {
        "userId": 5496,
        "userCellphone": "13587693528",
        "userNickname": null,
        "companyNames": '杭州欧檬服饰有限公司',
        "relatedApplications": "1",
        "userSource": 1,
        "userRegisterTime": "2018-12-18 14:17:46",
        "userLastLoginTime": "2021-08-11 17:19:43",
        "userStatus": "y"
      },
      {
        "userId": 5495,
        "userCellphone": "13989838106",
        "userNickname": null,
        "companyNames": '杭州古登进出口有限公司',
        "relatedApplications": "1",
        "userSource": 1,
        "userRegisterTime": "2019-04-03 11:25:44",
        "userLastLoginTime": "2021-07-08 14:05:44",
        "userStatus": "y"
      },
      {
        "userId": 5494,
        "userCellphone": "15868129991",
        "userNickname": null,
        "companyNames": '杭州向鑫美服饰有限公司',
        "relatedApplications": "1,3",
        "userSource": 6,
        "userRegisterTime": "2019-01-26 15:01:22",
        "userLastLoginTime": "2021-08-27 16:24:30",
        "userStatus": "y"
      },
      {
        "userId": 5493,
        "userCellphone": "15258875561",
        "userNickname": null,
        "companyNames": "杭州言诺纺织品有限公司",
        "relatedApplications": "1",
        "userSource": 10,
        "userRegisterTime": "2018-12-28 13:54:22",
        "userLastLoginTime": "2021-01-05 14:35:22",
        "userStatus": "y"
      },
      {
        "userId": 5492,
        "userCellphone": "13588099349",
        "userNickname": null,
        "companyNames": "杭州垂直纺织科技有限公司",
        "relatedApplications": "1",
        "userSource": 10,
        "userRegisterTime": "2018-12-20 21:28:04",
        "userLastLoginTime": "2020-11-05 10:29:02",
        "userStatus": "y"
      }
    ]
  },
  "msg": "操作成功"
}

export const userDetailData = {
  "code": 200,
  "success": true,
  "data": {
    "userId": 5501,
    "userCellphone": "15068885130",
    "userNickname": null,
    "companyNames": '利辰服饰',
    "relatedApplications": "1",
    "userSource": 1,
    "userRegisterTime": "2019-05-16 16:20:28",
    "userLastLoginTime": "2021-08-27 15:42:28",
    "userStatus": "y"
  },
  "msg": "操作成功"
}

export const loginLogListData = {
  "code": 200,
  "success": true,
  "data": {
    "current": 1,
    "size": 10,
    "total": 24,
    "pages": 3,
    "records": [
      {
        "loginId": 6996,
        "loginResult": "y",
        "userId": 429,
        "userCellphone": "18355517723",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"errMsg\":\"getSystemInfo:ok\",\"brand\":\"Xiaomi\",\"model\":\"MI 6\",\"pixelRatio\":3,\"screenWidth\":360,\"screenHeight\":640,\"windowWidth\":360,\"windowHeight\":640,\"statusBarHeight\":20,\"language\":\"zh-CN\",\"system\":\"Android 8.0.0\",\"version\":\"1.9.9.81011\",\"fontSizeSetting\":\"\",\"platform\":\"android\",\"SDKVersion\":\"\",\"windowTop\":0,\"windowBottom\":0,\"safeArea\":{\"left\":0,\"right\":360,\"top\":20,\"bottom\":640,\"width\":360,\"height\":620},\"safeAreaInsets\":{\"top\":20,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"ABC9F916C9EC593BAFCC413695E420B1\"}",
        "loginTime": "2021-08-27 17:31:46",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      },
      {
        "loginId": 6995,
        "loginResult": "y",
        "userId": 429,
        "userCellphone": "18355517723",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"windowTop\":0,\"windowBottom\":0,\"windowWidth\":375,\"windowHeight\":667,\"pixelRatio\":2.0000000298023224,\"screenWidth\":375,\"screenHeight\":667,\"language\":\"zh-CN\",\"statusBarHeight\":0,\"system\":\"iOS 13.2.3\",\"platform\":\"ios\",\"model\":\"iPhone\",\"safeArea\":{\"left\":0,\"right\":375,\"top\":0,\"bottom\":667,\"width\":375,\"height\":667},\"safeAreaInsets\":{\"top\":0,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"16300540084858396454\",\"errMsg\":\"getSystemInfo:ok\"}",
        "loginTime": "2021-08-24 16:49:45",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      },
      {
        "loginId": 6994,
        "loginResult": "y",
        "userId": 429,
        "userCellphone": "18355517723",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"errMsg\":\"getSystemInfo:ok\",\"brand\":\"Xiaomi\",\"model\":\"MI 6\",\"pixelRatio\":3,\"screenWidth\":360,\"screenHeight\":640,\"windowWidth\":360,\"windowHeight\":640,\"statusBarHeight\":20,\"language\":\"zh-CN\",\"system\":\"Android 8.0.0\",\"version\":\"1.9.9.81011\",\"fontSizeSetting\":\"\",\"platform\":\"android\",\"SDKVersion\":\"\",\"windowTop\":0,\"windowBottom\":0,\"safeArea\":{\"left\":0,\"right\":360,\"top\":20,\"bottom\":640,\"width\":360,\"height\":620},\"safeAreaInsets\":{\"top\":20,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"|||0fdb812264534a12|8aea9c851460416e8ab5ffc3d8388637|\"}",
        "loginTime": "2021-08-19 14:47:41",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      },
      {
        "loginId": 6993,
        "loginResult": "y",
        "userId": 429,
        "userCellphone": "18355517723",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"windowTop\":0,\"windowBottom\":0,\"windowWidth\":375,\"windowHeight\":667,\"pixelRatio\":2.0000000298023224,\"screenWidth\":375,\"screenHeight\":667,\"language\":\"zh-CN\",\"statusBarHeight\":0,\"system\":\"iOS 13.2.3\",\"platform\":\"ios\",\"model\":\"iPhone\",\"safeArea\":{\"left\":0,\"right\":375,\"top\":0,\"bottom\":667,\"width\":375,\"height\":667},\"safeAreaInsets\":{\"top\":0,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"16300540084858396454\",\"errMsg\":\"getSystemInfo:ok\"}",
        "loginTime": "2021-08-16 09:24:08",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      },
      {
        "loginId": 6992,
        "loginResult": "y",
        "userId": 429,
        "userCellphone": "18355517723",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"errMsg\":\"getSystemInfo:ok\",\"brand\":\"Xiaomi\",\"model\":\"MI 6\",\"pixelRatio\":3,\"screenWidth\":360,\"screenHeight\":640,\"windowWidth\":360,\"windowHeight\":640,\"statusBarHeight\":20,\"language\":\"zh-CN\",\"system\":\"Android 8.0.0\",\"version\":\"1.9.9.81011\",\"fontSizeSetting\":\"\",\"platform\":\"android\",\"SDKVersion\":\"\",\"windowTop\":0,\"windowBottom\":0,\"safeArea\":{\"left\":0,\"right\":360,\"top\":20,\"bottom\":640,\"width\":360,\"height\":620},\"safeAreaInsets\":{\"top\":20,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"|||0fdb812264534a12|8aea9c851460416e8ab5ffc3d8388637|\"}",
        "loginTime": "2021-08-12 11:25:15",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      },
      {
        "loginId": 6991,
        "loginResult": "y",
        "userId": 5494,
        "userCellphone": "13350886155",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"errMsg\":\"getSystemInfo:ok\",\"brand\":\"HUAWEI\",\"model\":\"LYA-AL00\",\"pixelRatio\":2.549999952316284,\"screenWidth\":424,\"screenHeight\":918,\"windowWidth\":424,\"windowHeight\":918,\"statusBarHeight\":29,\"language\":\"zh-CN\",\"system\":\"Android 10\",\"version\":\"1.9.9.81011\",\"fontSizeSetting\":\"\",\"platform\":\"android\",\"SDKVersion\":\"\",\"windowTop\":0,\"windowBottom\":0,\"safeArea\":{\"left\":0,\"right\":424,\"top\":29,\"bottom\":918,\"width\":424,\"height\":889},\"safeAreaInsets\":{\"top\":29,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"A29FED5C5A2417F331F1049714A409CB\"}",
        "loginTime": "2021-08-09 15:24:30",
        "loginIp": "124.90.87.6",
        "loginMethod": "sms"
      },
      {
        "loginId": 6990,
        "loginResult": "n",
        "userId": 5494,
        "userCellphone": "13350886155",
        "userAuthInfo": "e10adc3949ba59abbe56e057f20f883e",
        "userDeviceInfo": "{\"errMsg\":\"getSystemInfo:ok\",\"brand\":\"HUAWEI\",\"model\":\"LYA-AL00\",\"pixelRatio\":2.549999952316284,\"screenWidth\":424,\"screenHeight\":918,\"windowWidth\":424,\"windowHeight\":918,\"statusBarHeight\":29,\"language\":\"zh-CN\",\"system\":\"Android 10\",\"version\":\"1.9.9.81011\",\"fontSizeSetting\":\"\",\"platform\":\"android\",\"SDKVersion\":\"\",\"windowTop\":0,\"windowBottom\":0,\"safeArea\":{\"left\":0,\"right\":424,\"top\":29,\"bottom\":918,\"width\":424,\"height\":889},\"safeAreaInsets\":{\"top\":29,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"A29FED5C5A2417F331F1049714A409CB\"}",
        "loginTime": "2021-08-08 13:09:11",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      },
      {
        "loginId": 6989,
        "loginResult": "y",
        "userId": 5498,
        "userCellphone": "18915777965",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"windowTop\":0,\"windowBottom\":0,\"windowWidth\":375,\"windowHeight\":667,\"pixelRatio\":2.0000000298023224,\"screenWidth\":375,\"screenHeight\":667,\"language\":\"zh-CN\",\"statusBarHeight\":0,\"system\":\"iOS 13.2.3\",\"platform\":\"ios\",\"model\":\"iPhone\",\"safeArea\":{\"left\":0,\"right\":375,\"top\":0,\"bottom\":667,\"width\":375,\"height\":667},\"safeAreaInsets\":{\"top\":0,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"16213519571157040131\",\"errMsg\":\"getSystemInfo:ok\"}",
        "loginTime": "2021-08-05 19:15:24",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      },
      {
        "loginId": 6988,
        "loginResult": "y",
        "userId": 5501,
        "userCellphone": "18917889788",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"windowTop\":0,\"windowBottom\":0,\"windowWidth\":375,\"windowHeight\":667,\"pixelRatio\":2.0000000298023224,\"screenWidth\":375,\"screenHeight\":667,\"language\":\"zh-CN\",\"statusBarHeight\":0,\"system\":\"iOS 13.2.3\",\"platform\":\"ios\",\"model\":\"iPhone\",\"safeArea\":{\"left\":0,\"right\":375,\"top\":0,\"bottom\":667,\"width\":375,\"height\":667},\"safeAreaInsets\":{\"top\":0,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"16213519571157040131\",\"errMsg\":\"getSystemInfo:ok\"}",
        "loginTime": "2021-08-04 09:42:28",
        "loginIp": "124.90.87.6",
        "loginMethod": "sms"
      },
      {
        "loginId": 6987,
        "loginResult": "y",
        "userId": 429,
        "userCellphone": "18355517723",
        "userAuthInfo": "",
        "userDeviceInfo": "{\"windowTop\":0,\"windowBottom\":0,\"windowWidth\":375,\"windowHeight\":667,\"pixelRatio\":2.0000000298023224,\"screenWidth\":375,\"screenHeight\":667,\"language\":\"zh-CN\",\"statusBarHeight\":0,\"system\":\"iOS 13.2.3\",\"platform\":\"ios\",\"model\":\"iPhone\",\"safeArea\":{\"left\":0,\"right\":375,\"top\":0,\"bottom\":667,\"width\":375,\"height\":667},\"safeAreaInsets\":{\"top\":0,\"right\":0,\"bottom\":0,\"left\":0},\"deviceId\":\"16213519571157040131\",\"errMsg\":\"getSystemInfo:ok\"}",
        "loginTime": "2021-08-02 14:35:56",
        "loginIp": "124.90.87.6",
        "loginMethod": "pwd"
      }
    ]
  },
  "msg": "操作成功"
}
<template>
    <div class="horizontal-nav-wrap" v-if="navShow">
        <div class="horizontal-nav">
            <div class="item" 
                :class="{active: item.name === curNavName}" 
                v-for="item in navList" :key="item.name"
                @click="toLink(item)"
            >
                {{item.meta.title}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'horizontal-nav',
    data() {
        return {
            navShow: false,
            navList: [],
            curNavName: ''
        }
    },
    watch: {
        '$route': {
            handler: function(route) {
                // console.log('horizontal-nav', route)
                let routeLevel = 0
                let horizonalNavs = route.matched.filter((r, i) => {
                    if (r.meta && r.meta.hasHorizonalNav) {
                        routeLevel = i
                        return true
                    } else {
                        return false
                    }
                })
                this.curNavName = route.matched[routeLevel+1].name
                // console.log('routeLevel', routeLevel)
                if (horizonalNavs.length > 0) {
                    this.navShow = true
                    this.$emit('horizontal-nav-emit', true)
                    try {
                        // 获取导航列表
                        let routeArray = []
                        routeArray = this.$router.options.routes.filter(r => r.name === 'BasicLayout')[0].children
                        for (let i = 0; i < routeLevel; i ++) {
                            routeArray = routeArray.filter(r => r.name === route.matched[i+1].name)[0].children
                        }
                        // console.log('horizontal-nav',routeArray)
                        this.navList = routeArray
                    } catch (error) {
                        console.warn('horizontal-nav', error)
                    }
                } else {
                    this.navShow = false
                    this.$emit('horizontal-nav-emit', false)
                }
            },
            immediate: true
        }
    },
    methods: {
        toLink(item) {
            this.$router.push(item.path).catch(error => error)
        }
    }
}
</script>

<style scoped>

</style>
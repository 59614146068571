<template>
    <div v-if="isTabContent">
        <div class="tab-handles-area" v-if="handleArea">
            <div class="btns">
                <slot name="handles-btns"></slot>
            </div>
            <div class="right">
                <slot name="handles-right"></slot>
            </div>
        </div>
        <div class="tab-page-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-head-wrapper',
    props: {
        handleArea: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
           isTabContent: true 
        }
    },
    provide() {
        return {
            tabReload: this.tabReload
        }
    },
    methods: {
        tabReload() {
            this.isTabContent = false
            this.$nextTick(() => {
                this.isTabContent = true
            })
        }
    }
}
</script>
import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'

import { userListData, userDetailData, loginLogListData } from './test-data/02.js'
// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.user_base_url)


// 用户数据、管理员数据 - 接口列表
const apis = {
    // 管理员信息 - 修改密码
    updatePwd: '/yijiajia-admin-user-info/updatePwd',
    // 管理员信息 - 通过手机号修改密码
    updatePwdByPhone: '/yijiajia-admin-user-info/updatePwdByPhone',
    // 获取管理员信息
    getAdminInfo: '/yijiajia-admin-user-info/getAdminInfo',
    // 管理员信息 - 修改头像
    updateAvatar: '/yijiajia-admin-user-info/updateAvatar',

    // 用户数据 - 分页列表
    userList: '/yijiajia-user-info/page',
    // 用户数据 - 重置密码
    resetPwd: '/yijiajia-user-info/resetPwd',
    // 用户数据 - 更新状态
    updateState: '/yijiajia-user-info/updateState',
    // 用户数据 - 修改昵称
    updateNickname: '/yijiajia-user-extra-info/updateNickname',
    // 用户数据 - 登录日志
    loginLogList: '/yijiajia-user-login/page',

    // 用户数据 - 用户详情
    userDetail: '/yijiajia-user-info/detail',
}

// 管理员信息 - 修改密码
export function updatePwd(parameter) {
    return request({
        url: apis.updatePwd,
        method: 'post',
        data: parameter
    })
}

// 管理员信息 - 通过手机号修改密码
export function updatePwdByPhone(parameter) {
    return request({
        url: apis.updatePwdByPhone,
        method: 'post',
        data: parameter
    })
}

// 获取管理员信息
export function getAdminInfo(parameter) {
    return request({
        url: apis.getAdminInfo,
        method: 'get',
        params: parameter
    })
}
// 管理员信息 - 修改头像
export function updateAvatar(parameter) {
    return request({
        url: apis.updateAvatar,
        method: 'post',
        data: parameter
    })
}


// 用户数据 - 分页列表
// export function userList(parameter) {
//     return request({
//         url: apis.userList,
//         method: 'get',
//         params: parameter
//     })
// }
export function userList(parameter) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(userListData)
        }, 300)
    })
}

// 用户数据 - 重置密码
export function resetPwd(parameter) {
    return request({
        url: apis.resetPwd,
        method: 'post',
        data: parameter
    })
}

// 用户数据 - 更新状态
export function updateState(parameter) {
    return request({
        url: apis.updateState,
        method: 'post',
        data: parameter
    })
}

// 用户数据 - 修改昵称
export function updateNickname(parameter) {
    return request({
        url: apis.updateNickname,
        method: 'post',
        data: parameter
    })
}

// 用户数据 - 登录日志
// export function loginLogList(parameter) {
//     return request({
//         url: apis.loginLogList,
//         method: 'get',
//         params: parameter
//     })
// }
export function loginLogList(parameter) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(loginLogListData)
        }, 300)
    })
}


// 用户数据 - 用户详情
// export function userDetail(parameter) {
//     return request({
//         url: apis.userDetail,
//         method: 'get',
//         params: parameter
//     })
// }

export function userDetail(parameter) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(userDetailData)
        }, 300)
    })
}


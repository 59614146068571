import Vue from 'vue'
import VueRouter from 'vue-router'
import BasicLayout from "../layouts/BasicLayout.vue"
import BasicLayout2 from "../layouts/BasicLayout2.vue"
import BasicLayout3 from "../layouts/BasicLayout3.vue"

import KeepAliveRouteView from '../layouts/KeepAliveRouteView.vue'

Vue.use(VueRouter)

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

const routes = [
  {
    path: '/',
    name: 'BasicLayout',
    component: RouteView,
    redirect: '/app-permissions',
    children: [
      {
        path: '/dynamic-data',
        name: 'dynamic-data',
        component: BasicLayout,
        redirect: '/dynamic-data/index',
        meta: { title: '动态数据'},
        children: [
          {
            path: '/dynamic-data/index',
            name: 'dynamic-data-index',
            component: () => import('@/components/Develop'),
            meta: { title: '首页' },
          }
        ]
      },
      {
        path: '/static-data',
        name: 'static-data',
        component: BasicLayout,
        redirect: '/static-data/user',
        meta: { title: '静态数据'},
        children: [
          {
            path: '/static-data/user',
            name: 'static-data-user',
            component: KeepAliveRouteView,
            meta: { title: '用户数据', icon: 'user-data', subType: 'tab' },
            redirect: '/static-data/user/list',
            children: [
              {
                path: '/static-data/user/list',
                name: 'static-data-user-list',
                component: () => import('@/views/static-data/user/list'),
                meta: {title: '用户列表', defaultTab: true, closeHidden: true}
              },
              {
                path: '/static-data/user/detail',
                name: 'static-data-user-detail',
                component: () => import('@/views/static-data/user/detail'),
                meta: {title: '用户详情'}
              },
              {
                path: '/static-data/user/login-log',
                name: 'static-data-user-login-log',
                component: () => import('@/views/static-data/user/login-log'),
                meta: {title: '登录日志'}
              }
            ]
          },
          {
            path: '/static-data/company',
            name: 'static-data-company',
            component: RouteView,
            meta: { title: '企业数据', icon: 'company-data', hasHorizonalNav: true, },
            redirect: '/static-data/company/workbench',
            children: [
              {
                path: '/static-data/company/workbench',
                name: 'static-data-company-workbench',
                component: KeepAliveRouteView,
                meta: { title: '工作台', subType: 'tab'},
                redirect: '/static-data/company/workbench/pending',
                children: [
                  {
                    path: '/static-data/company/workbench/pending',
                    name: 'static-data-company-workbench-pending',
                    component: () => import('@/views/static-data/company/workbench/pending-list'),
                    meta: { title: '待审核企业', defaultTab: true, closeHidden: true },
                  },
                  {
                    path: '/static-data/company/workbench/incomplete',
                    name: 'static-data-company-workbench-incomplete',
                    component: () => import('@/views/static-data/company/workbench/incomplete-list'),
                    meta: { title: '未完善企业', defaultTab: true, closeHidden: true },
                  },
                  {
                    path: '/static-data/company/workbench/rejected',
                    name: 'static-data-company-workbench-rejected',
                    component: () => import('@/views/static-data/company/workbench/rejected-list'),
                    meta: { title: '已驳回企业', defaultTab: true, closeHidden: true },
                  },
                  {
                    path: '/static-data/company/workbench/pending-detail',
                    name: 'static-data-company-workbench-pending-detail',
                    component: () => import('@/views/static-data/company/workbench/pending-detail'),
                    meta: { title: '待审核企业详情'},
                  },
                  {
                    path: '/static-data/company/workbench/incomplete-detail',
                    name: 'static-data-company-workbench-incomplete-detail',
                    component: () => import('@/views/static-data/company/workbench/incomplete-detail'),
                    meta: { title: '未完善企业详情'},
                  },
                  {
                    path: '/static-data/company/workbench/rejected-detail',
                    name: 'static-data-company-workbench-rejected-detail',
                    component: () => import('@/views/static-data/company/workbench/rejected-detail'),
                    meta: { title: '已驳回企业详情'},
                  }
                ]
              },
              {
                path: '/static-data/company/maintenance',
                name: 'static-data-company-maintenance',
                component: KeepAliveRouteView,
                meta: { title: '数据维护', subType: 'tab'},
                redirect: '/static-data/company/maintenance/list',
                children: [
                  {
                    path: '/static-data/company/maintenance/list',
                    name: 'static-data-company-maintenance-list',
                    component: () => import('@/views/static-data/company/maintenance/list'),
                    meta: { title: '企业查询', defaultTab: true, closeHidden: true },
                  },
                  {
                    path: '/static-data/company/maintenance/detail',
                    name: 'static-data-company-maintenance-detail',
                    component: () => import('@/views/static-data/company/maintenance/detail'),
                    meta: { title: '企业详情'},
                  },
                  {
                    path: '/static-data/company/maintenance/add',
                    name: 'static-data-company-maintenance-add',
                    component: () => import('@/views/static-data/company/maintenance/add'),
                    meta: { title: '新增企业'},
                  },
                ]
              },
              {
                path: '/static-data/company/member',
                name: 'static-data-company-member',
                component: KeepAliveRouteView,
                meta: { title: '会员企业', subType: 'tab' }, 
                redirect: '/static-data/company/member/list',
                children: [
                  {
                    path: '/static-data/company/member/list',
                    name: 'static-data-company-member-list',
                    component: () => import('@/views/static-data/company/member/list'),
                    meta: { title: '企业查询', defaultTab: true, closeHidden: true },
                  },
                  {
                    path: '/static-data/company/member/detail',
                    name: 'static-data-company-member-detail',
                    component: () => import('@/views/static-data/company/member/detail'),
                    meta: { title: '企业详情'},
                  },
                ]
              },
              {
                path: '/static-data/company/template',
                name: 'static-data-company-template',
                component: () => import('@/views/static-data/company/template/index'),
                meta: { title: '模板配置' },
              }
            ],
          },
          {
            path: '/static-data/goods',
            name: 'static-data-goods',
            component: RouteView,
            meta: { title: '商品数据', icon: 'goods-data', hasHorizonalNav: true},
            redirect: '/static-data/goods/list',
            children: [
              {
                path: '/static-data/goods/list',
                name: 'static-data-goods-list',
                component: KeepAliveRouteView,
                meta: { title: '商品列表', subType: 'tab'},
                redirect: '/static-data/goods/list/list',
                children: [
                  {
                    path: '/static-data/goods/list/list',
                    name: 'static-data-goods-list-list',
                    component: () => import('@/views/static-data/goods/list/list'),
                    meta: { title: '商品查询', defaultTab: true, closeHidden: true },
                  },
                  {
                    path: '/static-data/goods/list/add',
                    name: 'static-data-goods-list-add',
                    component: () => import('@/views/static-data/goods/list/add'),
                    meta: { title: '新增商品'},
                  },
                  {
                    path: '/static-data/goods/list/detail',
                    name: 'static-data-goods-list-rejected',
                    component: () => import('@/views/static-data/goods/list/detail'),
                    meta: { title: '商品详情'},
                  },
                ]
              },
              {
                path: '/static-data/goods/maintenance',
                name: 'static-data-goods-maintenance',
                component: () => import('@/views/static-data/goods/maintenance/index'),
                meta: { title: '数据维护' },
              },
              {
                path: '/static-data/goods/template',
                name: 'static-data-goods-template',
                component: () => import('@/views/static-data/goods/template/index'),
                meta: { title: '模板配置' },
              }
            ],
          },
          {
            path: '/static-data/production',
            name: 'static-data-production',
            component: () => import('@/components/Develop'),
            meta: { title: '生产数据' },
          },
          {
            path: '/static-data/stock',
            name: 'static-data-stock',
            component: () => import('@/components/Develop'),
            meta: { title: '库存数据' },
          },
          {
            path: '/static-data/deal',
            name: 'static-data-deal',
            component: () => import('@/components/Develop'),
            meta: { title: '交易数据' },
          },
          {
            path: '/static-data/logistics',
            name: 'static-data-logistics',
            component: () => import('@/components/Develop'),
            meta: { title: '物流数据' },
          },
          {
            path: '/static-data/market',
            name: 'static-data-market',
            component: () => import('@/components/Develop'),
            meta: { title: '市场数据' },
          },
          {
            path: '/static-data/finance',
            name: 'static-data-finance',
            component: () => import('@/components/Develop'),
            meta: { title: '金融数据' },
          },
          {
            path: '/static-data/policy',
            name: 'static-data-policy',
            component: () => import('@/components/Develop'),
            meta: { title: '政策数据' },
          },
          {
            path: '/static-data/other',
            name: 'static-data-other',
            component: () => import('@/components/Develop'),
            meta: { title: '其它产业数据' },
          },
        ]
      },
      {
        path: '/tag-data',
        name: 'tag-data',
        component: BasicLayout2,
        redirect: '/tag-data/index',
        meta: { title: '标签数据'},
        children: [
          {
            path: '/tag-data/index',
            name: 'tag-data-index',
            component: RouteView,
            redirect: '/tag-data/index/structural',
            meta: { 
              title: '标签数据',
              icon: 'tag',
              hasHorizonalNav: true,
            },
            children: [
              {
                path: '/tag-data/index/structural',
                name: 'tag-data-structural',
                meta: { title: '结构性标签' },
                component: () => import('@/views/tag-data/structural'),
              },
              {
                path: '/tag-data/index/non-structural',
                name: 'tag-data-non-structural',
                meta: { title: '非结构性标签' },
                component: () => import('@/views/tag-data/non-structural'),
              },
            ]
          }
        ]
      },
      {
        path: '/app-permissions',
        name: 'app-permissions',
        component: BasicLayout,
        redirect: '/app-permissions/app-registry',
        meta: { title: '应用权限'},
        children: [
          {
            path: '/app-permissions/app-registry',
            name: 'app-registry',
            component: KeepAliveRouteView,
            redirect: '/app-permissions/app-registry/list',
            meta: { title: '应用注册', icon: 'app-reg', subType: 'tab'},
            children: [
              {
                path: '/app-permissions/app-registry/list',
                name: 'app-registry-list',
                component: () => import('@/views/app-permissions/app-registry/list'),
                meta: { title: '应用列表', defaultTab: true, closeHidden: true},
              },
              {
                path: '/app-permissions/app-registry/detail',
                name: 'app-registry-detail',
                component: () => import('@/views/app-permissions/app-registry/detail'),
                meta: { title: '应用详情' },
              },
              {
                path: '/app-permissions/app-registry/add',
                name: 'app-registry-add',
                component: () => import('@/views/app-permissions/app-registry/add'),
                meta: { title: '新增应用' },
              },
              // {
              //   path: '/app-permissions/app-registry/key-management',
              //   name: 'app-registry-key-management',
              //   component: () => import('@/views/app-permissions/app-registry/key-management'),
              //   meta: { title: '密钥管理' },
              // },
            ]
          },
          {
            path: '/app-permissions/data-permission',
            name: 'data-permission',
            component: KeepAliveRouteView,
            redirect: '/app-permissions/data-permission/index',
            meta: { title: '数据权限',  icon: 'data-permission', subType: 'tab'},
            children: [
              {
                path: '/app-permissions/data-permission/index',
                name: 'data-permission-index',
                component: () => import('@/components/Develop'),
                meta: { title: '数据权限', defaultTab: true, closeHidden: true},
              },
            ]
          }
        ]
      },
      {
        path: '/account-permissions',
        name: 'account-permissions',
        component: BasicLayout,
        redirect: '/account-permissions/index',
        meta: { title: '账户权限', icon: 'data-model'},
        children: [
          {
            path: '/account-permissions/index',
            name: 'account-permissions-index',
            component: () => import('@/components/Develop'),
            meta: { title: '首页' },
          }
        ]
      }
    ]
  },

  {
    path: '/user',
    name: 'user',
    component: BasicLayout3,
    redirect: '/user/profile',
    children: [
      {
        path: '/user/profile',
        name: 'user-profile',
        component: () => import('@/views/user/profile'),
        meta: {title: '个人中心', showHomeLink: true}
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/login')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'hash', // history
  // base: process.env.BASE_URL,
  routes
})

export default router

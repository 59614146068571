import Vue from 'vue'
import Loading from '@/components/Loading.vue'


/* 方便传递参数时使用 */
Vue.prototype.$bus = new Vue()

/* 全局loading */
let loadingVm
function showLoading (options) {
    console.log('showLoading')
    if (loadingVm) {
        // hideLoading()
        return
    }
    options = {
        tip: options && options.tip || ''
    }
    let loadingComp = Vue.extend({
        name: 'loadingComp',
        render: (h) => h(Loading, {props: {tip: options.tip}})
    })
    loadingVm = new loadingComp().$mount()
    document.body.appendChild(loadingVm.$el)
}

function hideLoading() {
    console.log('hideLoading')
    if (loadingVm) {
        document.body.removeChild(loadingVm.$el)
        loadingVm = null
    }
}

Vue.prototype.$showLoading = showLoading
Vue.prototype.$hideLoading = hideLoading

/*全局directive*/
Vue.directive('clickoutside', {
    bind(el, binding) {
        console.log(el, binding)
        function handler(e) {
            if (el.contains(e.target)) {
                return
            }
            if (binding.value && typeof binding.value === 'function') {
                binding.value(e)
            }
        }
        el._clickoutside_fn = handler
        document.addEventListener('click', handler)
    },
    unbind(el) {
        // 解除事件绑定
        document.removeEventListener('click', el._clickoutside_fn)
        delete el._clickoutside_fn
    }
})

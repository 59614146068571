<template>
  <div class="fullscreen-loading">
      <a-spin />
      <span class="tip">{{tip}}</span>
  </div>
</template>
<script>
export default {
  name: 'fullscreen-loading',
  props: {
    tip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.fullscreen-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
  z-index: 999999;
  .tip {
    margin-top: 8px;
    color: #0267ED;
  }
}
</style>
import md5 from 'md5'
import API_PREFIX from '@/config/api.prefix'
import apiKeys from "@/config/api.key"
import Thumbor from '@/utils/thumbor-url-builder'
/*
    签名算法 - java接口
*/
export function getSign (obj, secretKey) {
	if (Object.prototype.toString.call(obj) == "[object Object]") {
		let str = '';
		let sortArr = Object.keys(obj).sort()
		sortArr.forEach(key => {
			if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '' ) {
                // console.log(key, obj[key])
                let value = obj[key]
                if (typeof value === 'object') {
                    value = JSON.stringify(value)
                }
				str += key + '=' + value + '&'
			}
		})
        str = str + 'secretKey=' + secretKey // az5CAEUb0qsEAZRThvmp8FD8tawR8jrx
        console.log('str', str)
        // console.log(md5(str).toUpperCase())
        return md5(str).toUpperCase()
	}
	return '';
}

/*
    生成32位随机数的方法
*/
export function createNonce() {
    let arr = [
        'A','B','C','D','E','F','G','H','I','J','K','L','M','N',
        'O','P','Q','R','S','T','U','V','W','X','Y','Z', 
        '0', '1', '2', '3', '4','5','6','7','8', '9'
    ]
    let str = ''
    let L = arr.length - 1 
    for (let i = 0; i < 32; i ++) {
        str += arr[getRandomIntInclusive(0, L)]
    }
    return str
}

/* 得到一个两数之间的随机整数，包括两个数在内 */
export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值 
}

/*
    签名算法 - php接口
*/
export function getPhpSign (obj, apiKey) {
	if (Object.prototype.toString.call(obj) == "[object Object]") {
		let str = '';
		let sortArr = Object.keys(obj).sort()
		sortArr.forEach(key => {
			if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '' ) {
                // console.log(key, obj[key])
                let value = obj[key]
                if (typeof value === 'object') {
                    value = JSON.stringify(value)
                }
				str += key + value
			}
		})
        str = str + apiKey
        return md5(str)
	}
	return '';
}

/*
    minIO 图片上传
    后端返回地址  转换为 前端展示地址
*/

// 初始化Thumbor实例
const thumbor = new Thumbor(apiKeys.imageSecurityKey, API_PREFIX.file_prefix_url)

// 转化为所需url, 并返回
const SECURITY = true // 是否返回安全地址
export function buildThumborUrl(url, config) {

    if (!url || typeof url !== 'string') {
        console.warn('The url is required and it should be a string')
        return
    }

    config = config || {}

    if (config.width && isNaN(config.width)) {
        console.warn('The width should be a number')
        return
    }
    if (config.height && isNaN(config.height)) {
        console.warn('The height should be a number')
        return
    }

    let width = config.width || 1000
    let height = config.height || 0

    if (SECURITY) {
        return thumbor
            .setImagePath(url)
            .resize(width, height)
            .buildUrl()
    }
    url = url.charAt(0) === '/' ? url.substring(1) : url
    return `${API_PREFIX.file_prefix_url}/unsafe/${width}x${height}/${url}`
}

/*
    防抖函数
*/
export function debounce (fn, delay = 300) {
    let timer = null
    return function () {
        let ctx = this
        let args = arguments
        if (timer) clearTimeout(timer)
        timer = setTimeout(function() {
            fn.apply(ctx, args)
        }, delay)
    }
}

/*
    节流函数
*/
export function throttle (fn, delay = 1000) {
    let timer = null
    return function () {
        let ctx = this
        let args = arguments
        if (!timer) {
            timer = setTimeout(function() {
                fn.apply(ctx, args)
                timer = null
            }, delay)
        }
    }
}

/* 
    获取到指定定位元素的offsetLeft; offsetTop
    
    @params node： 当前元素
    @params dir:   left / top
    @params targetNode 指定父级定位元素
*/
export function getOffsetDistance(node, dir, targetNode) {
    let dis = 0
    let props = {left: 'offsetLeft', top: 'offsetTop'}
    dir = props[dir] || 'offsetLeft'
    targetNode = targetNode || document.querySelector('.mid-pla-main-content')
    function getDis(node, dir, targetNode) {
        dis += node[dir]
        // console.log(dis)
        if (node.offsetParent && node.offsetParent != targetNode) {
        getDis(node.offsetParent, dir, targetNode)
        }
    }
    getDis(node, dir, targetNode)
    return dis
}

/*
    深拷贝
*/ 
export function deepClone(source) {
    if (!source) return
    let target;
    if (typeof source === 'object') {
        // 根据source类型判断是新建一个数组还是对象
        target = Array.isArray(source) ? [] : {}
        // 遍历source，并且判断是source的属性才拷贝
        for (let key in source) {
            if (source.hasOwnProperty(key)) {
                if (typeof source[key] !== 'object') {
                    target[key] = source[key]
                } else {
                    // 如果内部属性存在复杂数据类型，使用递归实现深拷贝
                    target[key] = deepClone(source[key])
                }
            }
        }
    } else {
      target = source
    }
    return target
}







import API_ENV from "./api.env"

let API_PREFIX = {}

/*
    static_prefix_url     静态资源地址前缀1
    file_prefix_url       静态资源地址前缀2
    dw_base_url         数字云仓 - 临时使用
    yjj_base_url        衣加加app - 临时使用
    login_base_url      登录模块
    open_base_url       开放平台-应用权限模块
    user_base_url       用户模块
    tag_base_url        标签模块
    company_base_url    企业模块
    message_base_url    消息服务
    goods_base_url      商品模块
    system_base_url     系统模块
*/

if (API_ENV === 'local') {
    API_PREFIX = {
        base_url: 'http://10.10.1.74:10001',
        login_base_url: 'https://api.dev.ysintelligent.com/yijiajia_admin/p',
        dw_base_url: 'https://api.dev.ysintelligent.com/digital_warehouse', // 数字云仓 - 临时使用
        yjj_base_url: 'https://api.dev.ysintelligent.com/yijiajia_app', // 衣加加app - 临时使用
        ip_parse_url: 'https://api.dev.ysintelligent.com/global_ip_php',
        static_prefix_url: 'https://static.dev.ysintelligent.com',
        file_prefix_url: 'https://oss.dev.yijiajiayun.com',

        open_base_url: 'http://10.10.1.74:10001/openplatform', // 'https:/http://10.10.1.69:10001.dev.ysintelligent.com/open_platform', 
        user_base_url: 'http://10.10.1.74:10001/user',          
        tag_base_url: 'http://10.10.1.74:10001/tag',  
        company_base_url: 'http://10.10.1.74:10001/enterprise',
        message_base_url: 'http://10.10.1.74:10001/message',
        file_base_url: 'http://10.10.1.74:10001/file',
        goods_base_url: 'http://10.10.1.74:10001/commodity',
        system_base_url: 'http://10.10.1.74:10001/system',
    }
}

if (API_ENV === 'dev') {
    API_PREFIX = {
        base_url: 'https://api.dev.ysintelligent.com',
        login_base_url: 'https://api.dev.ysintelligent.com/yijiajia_admin/p',
        dw_base_url: 'https://api.dev.ysintelligent.com/digital_warehouse',
        yjj_base_url: 'https://api.dev.ysintelligent.com/yijiajia_app', // 衣加加app - 临时使用
        ip_parse_url: 'https://api.dev.ysintelligent.com/global_ip_php',
        static_prefix_url: 'https://static.dev.ysintelligent.com',
        file_prefix_url: 'https://oss.dev.yijiajiayun.com',

        open_base_url: 'https://api.dev.ysintelligent.com/openplatform',
        user_base_url: 'https://api.dev.ysintelligent.com/user',          
        tag_base_url: 'https://api.dev.ysintelligent.com/tag',  
        company_base_url: 'https://api.dev.ysintelligent.com/enterprise',
        message_base_url: 'https://api.dev.ysintelligent.com/message',
        file_base_url: 'https://api.dev.ysintelligent.com/file',
        goods_base_url: '',
    }
}

if (API_ENV === 'test') {
    API_PREFIX = {
        base_url: 'https://api.test.ysintelligent.com',
        login_base_url: 'https://api.test.ysintelligent.com/yijiajia_admin/p',
        dw_base_url: 'https://api.test.ysintelligent.com/digital_warehouse',
        yjj_base_url: 'https://api.test.ysintelligent.com/yijiajia_app', // 衣加加app - 临时使用
        ip_parse_url: 'https://api.test.ysintelligent.com/global_ip_php',
        static_prefix_url: 'https://static.test.ysintelligent.com',
        file_prefix_url: 'https://oss.test.yijiajiayun.com',

        open_base_url: 'https://api.test.ysintelligent.com/openplatform',
        user_base_url: 'https://api.test.ysintelligent.com/user',          
        tag_base_url: 'https://api.test.ysintelligent.com/tag',  
        company_base_url: 'https://api.test.ysintelligent.com/enterprise',
        message_base_url: 'https://api.test.ysintelligent.com/message',
        file_base_url: 'https://api.test.ysintelligent.com/file',
        goods_base_url: '',
    }
}

if (API_ENV === 'prod') {
    API_PREFIX = {
        base_url: 'https://api.ysintelligent.com',
        login_base_url: 'https://api.ysintelligent.com/yijiajia_admin/p',
        dw_base_url: 'https://api.ysintelligent.com/digital_warehouse',
        yjj_base_url: 'https://api.ysintelligent.com/yijiajia_app', // 衣加加app - 临时使用
        ip_parse_url: 'https://api.ysintelligent.com/global_ip_php',
        static_prefix_url: 'https://static.ysintelligent.com',
        file_prefix_url: 'https://oss.yijiajiayun.com',

        open_base_url: 'https://api.ysintelligent.com/openplatform',
        user_base_url: 'https://api.ysintelligent.com/user',          
        tag_base_url: 'https://api.ysintelligent.com/tag',
        company_base_url: 'https://api.ysintelligent.com/enterprise',
        message_base_url: 'https://api.ysintelligent.com/message',
        file_base_url: 'https://api.ysintelligent.com/file',
        goods_base_url: '',
    }
}

export default API_PREFIX